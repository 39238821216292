/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import React, { Suspense, Fragment, useState, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import {
  MenuIcon,
  SearchIcon,
  ShoppingBagIcon,
  XIcon
} from '@heroicons/react/outline'

import Logo from '../images/logo.png'
import get from 'lodash/get'

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example () {
  const [open, setOpen] = useState(false)

  const {
    allSite,
    allMedusaProducts,
    allMedusaImages
  } = useStaticQuery(graphql`
    query PageQuery {
      allSite {
        nodes {
          siteMetadata {
            currencies {
              symbol
            }
            hero {
              buttonHref
              buttonText
              images {
                src
              }
              subtitle
              title
            }
            author
            description
            favorites {
              href
              id
              imageAlt
              imageSrc
              name
              price
            }
            copyright
            footerNavigation {
              account {
                href
                name
              }
              company {
                href
                name
              }
              connect {
                href
                name
              }
              payment {
                href
                icon
                name
              }
              shop {
                href
                name
              }
            }
            navigation {
              categories {
                featured {
                  href
                  imageAlt
                  imageSrc
                  name
                }
                id
                name
                sections {
                  id
                  name
                  items {
                    href
                    name
                  }
                }
              }
              pages {
                href
                name
              }
            }
            title
          }
        }
      }

      allMedusaImages {
        edges {
          node {
            id
            image {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      allMedusaProducts(
        filter: { description: { regex: "/(.*)hoodie(.*)/" } }
      ) {
        edges {
          node {
            id
            title
            thumbnail {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
            handle
            collection_id
            options {
              values {
                value
              }
              title
            }
            variants {
              prices {
                amount
                currency_code
              }
            }
          }
        }
      }
    }
  `)

  const {
    hero,
    favorites,
    footerNavigation,
    copyright,
    navigation,
    pages,
    currencies
  } = get(allSite, `nodes.0.siteMetadata`, {})

  const getImage = (index, key = 'image.childImageSharp.gatsbyImageData') => {
    const res = get(allMedusaImages, `edges.${index}.node.${key}`, null)

    console.log(`got ${index}:${key} -> `, res)

    return res
  }

  const getProduct = (
    index,
    key = 'thumbnail.childImageSharp.gatsbyImageData'
  ) => {
    const res = get(allMedusaProducts, `edges.${index}.node.${key}`, null)

    console.log(`got ${index}:${key} -> `, res)

    return res
  }

  let i = 0

  const PaymentIcons = () => (
    <Suspense fallback={<span />}>
      <ul className={`inline-flex`}>
        {footerNavigation.payment
          .map(item => ({
            ...item,
            Icon: React.lazy(() => import(`${__dirname}/../icons/${item.icon}`))
          }))
          .map(({ name, href, Icon }) => (
            <a
              key={name}
              href={href}
              className='text-black hover:text-gray-300'
            >
              <span className='sr-only'>{name}</span>
              <Icon className='h-8 w-8' aria-hidden='true' />
            </a>
          ))}
      </ul>
    </Suspense>
  )

  return (
    <div className='bg-white'>
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-40 lg:hidden' onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 flex z-40'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <Dialog.Panel className='relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto'>
                <div className='px-4 pt-5 pb-2 flex'>
                  <button
                    type='button'
                    className='-m-2 p-2 rounded-md inline-flex items-center justify-center text-black'
                    onClick={() => setOpen(false)}
                  >
                    <span className='sr-only'>Close menu</span>
                    <XIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>

                {/* Links */}
                <Tab.Group as='div' className='mt-2'>
                  <div className='border-b border-gray-200'>
                    <Tab.List className='-mb-px flex px-4 space-x-8'>
                      {navigation.categories.map(category => (
                        <Tab
                          key={category.name}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? 'text-primary-600 border-primary-600'
                                : 'text-gray-900 border-transparent',
                              'flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium'
                            )
                          }
                        >
                          {category.name}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                  <Tab.Panels as={Fragment}>
                    {navigation.categories.map(category => (
                      <Tab.Panel
                        key={category.name}
                        className='pt-10 pb-8 px-4 space-y-10'
                      >
                        <div className='grid grid-cols-2 gap-x-4'>
                          {category.featured.map(item => (
                            <div
                              key={item.name}
                              className='group relative text-sm'
                            >
                              <div className='aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75'>
                                <img
                                  src={item.imageSrc}
                                  alt={item.imageAlt}
                                  className='object-center object-cover'
                                />
                              </div>
                              <a
                                href={item.href}
                                className='mt-6 block font-medium text-gray-900'
                              >
                                <span
                                  className='absolute z-10 inset-0'
                                  aria-hidden='true'
                                />
                                {item.name}
                              </a>
                              <p aria-hidden='true' className='mt-1'>
                                Shop now
                              </p>
                            </div>
                          ))}
                        </div>
                        {category.sections.map(section => (
                          <div key={section.name}>
                            <p
                              id={`${category.id}-${section.id}-heading-mobile`}
                              className='font-medium text-gray-900'
                            >
                              {section.name}
                            </p>
                            <ul
                              role='list'
                              aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                              className='mt-6 flex flex-col space-y-6'
                            >
                              {section.items.map(item => (
                                <li key={item.name} className='flow-root'>
                                  <a
                                    href={item.href}
                                    className='-m-2 p-2 block text-gray-500'
                                  >
                                    {item.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>

                <div className='border-t border-gray-200 py-6 px-4 space-y-6'>
                  {navigation.pages.map(page => (
                    <div key={page.name} className='flow-root'>
                      <a
                        href={page.href}
                        className='-m-2 p-2 block font-medium text-gray-900'
                      >
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>

                <div className='border-t border-gray-200 py-6 px-4 space-y-6'>
                  <div className='flow-root'>
                    <a
                      href='#'
                      className='-m-2 p-2 block font-medium text-gray-900'
                    >
                      Sign in
                    </a>
                  </div>
                  <div className='flow-root'>
                    <a
                      href='#'
                      className='-m-2 p-2 block font-medium text-gray-900'
                    >
                      Create account
                    </a>
                  </div>
                </div>

                <div className='border-t border-gray-200 py-6 px-4'>
                  <a href='#' className='-m-2 p-2 flex items-center'>
                    <img
                      src='https://tailwindui.com/img/flags/flag-canada.svg'
                      alt=''
                      className='w-5 h-auto block flex-shrink-0'
                    />
                    <span className='ml-3 block text-base font-medium text-gray-900'>
                      CAD
                    </span>
                    <span className='sr-only'>, change currency</span>
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Top navigation */}
      <nav className='relative z-20 bg-primary-600 backdrop-filter bg-opacity-90 backdrop-blur-xl'>
        <div className='max-w-7xl mx-auto h-10 px-4 flex items-center justify-between sm:px-6 lg:px-8'>
          {/* Currency selector */}
          <form className='hidden lg:block lg:flex-1'>
            <div className='flex'>
              <label htmlFor='desktop-currency' className='sr-only'>
                Currency
              </label>
              <div className='-ml-2 group relative bg-primary-600 border-transparent rounded-md focus-within:ring-2 focus-within:ring-white'>
                <select
                  id='desktop-currency'
                  name='currency'
                  className='bg-none bg-primary-600 border-transparent rounded-md py-0.5 pl-2 pr-5 flex items-center text-sm font-medium text-white group-hover:text-gray-100 focus:outline-none focus:ring-0 focus:border-transparent'
                >
                  {currencies.map((currency, i) => (
                    <option key={i}>{currency.symbol}</option>
                  ))}
                </select>
                <div className='absolute right-0 inset-y-0 flex items-center pointer-events-none'>
                  <svg
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 20'
                    className='w-5 h-5 text-gray-300'
                  >
                    <path
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1.5'
                      d='M6 8l4 4 4-4'
                    />
                  </svg>
                </div>
              </div>
            </div>
          </form>

          <p className='flex-1 text-center text-sm font-medium text-white lg:flex-none'>
            Get free delivery on orders over $100
          </p>

          <div className='hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6'>
            <a
              href='#'
              className='text-sm font-medium text-white hover:text-gray-100'
            >
              Create an account
            </a>
            <span className='h-6 w-px bg-white' aria-hidden='true' />
            <a
              href='#'
              className='text-sm font-medium text-white hover:text-gray-100'
            >
              Sign in
            </a>
          </div>
        </div>
      </nav>

      {/* Top navigation */}
      <nav
        aria-label='Top'
        className='sticky top-0 z-20 bg-secondary-100 bg-opacity-80 backdrop-filter backdrop-blur-xl'
      >
        <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='h-20 flex items-center'>
            <button
              type='button'
              className='p-2 rounded-md text-primary-600 lg:hidden'
              onClick={() => setOpen(true)}
            >
              <span className='sr-only'>Open menu</span>
              <MenuIcon className='h-6 w-6' aria-hidden='true' />
            </button>

            {/* Logo */}
            <div className='ml-4 flex lg:ml-0'>
              <a href='/'>
                <span className='sr-only'>LibertyApparel</span>
                <img
                  src={Logo}
                  className='h-12 w-auto'
                  //src='https://tailwindui.com/img/logos/workflow-mark.svg?color=red&shade=700'
                  alt=''
                />
              </a>
            </div>

            {/* Flyout menus */}
            <Popover.Group className='hidden lg:ml-8 lg:block lg:self-stretch'>
              <div className='h-full flex space-x-8'>
                {navigation.categories.map(category => (
                  <Popover key={category.name} className='flex'>
                    {({ open }) => (
                      <>
                        <div className='relative flex'>
                          <Popover.Button
                            className={classNames(
                              open
                                ? 'border-primary-600 text-primary-600'
                                : 'border-transparent text-gray-700 hover:text-gray-800',
                              'relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium uppercase border-b-2 -mb-px pt-px'
                            )}
                          >
                            <span className='text-secondary-600 hover:text-secondary-500'>
                              {category.name}
                            </span>
                          </Popover.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter='transition ease-out duration-200'
                          enterFrom='opacity-0'
                          enterTo='opacity-100'
                          leave='transition ease-in duration-150'
                          leaveFrom='opacity-100'
                          leaveTo='opacity-0'
                        >
                          <Popover.Panel className='absolute top-full inset-x-0 bg-white text-sm text-gray-500'>
                            {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                            <div
                              className='absolute inset-0 top-1/2 bg-white shadow'
                              aria-hidden='true'
                            />
                            {/* Fake border when menu is open */}
                            <div
                              className='absolute inset-0 top-0 h-px max-w-7xl mx-auto px-8'
                              aria-hidden='true'
                            >
                              <div
                                className={classNames(
                                  open ? 'bg-gray-200' : 'bg-transparent',
                                  'w-full h-px transition-colors ease-out duration-200'
                                )}
                              />
                            </div>

                            <div className='relative'>
                              <div className='max-w-7xl mx-auto px-8'>
                                <div className='grid grid-cols-2 gap-y-10 gap-x-8 py-16'>
                                  <div className='col-start-2 grid grid-cols-2 gap-x-8'>
                                    {category.featured.map(item => (
                                      <div
                                        key={item.name}
                                        className='group relative text-base sm:text-sm'
                                      >
                                        <div className='aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75'>
                                          <img
                                            src={item.imageSrc}
                                            alt={item.imageAlt}
                                            className='object-center object-cover'
                                          />
                                        </div>
                                        <a
                                          href={item.href}
                                          className='mt-6 block font-medium text-gray-900'
                                        >
                                          <span
                                            className='absolute z-10 inset-0'
                                            aria-hidden='true'
                                          />
                                          {item.name}
                                        </a>
                                        <p aria-hidden='true' className='mt-1'>
                                          Shop now
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                  <div className='row-start-1 grid grid-cols-3 gap-y-10 gap-x-8 text-sm'>
                                    {category.sections.map(section => (
                                      <div key={section.name}>
                                        <p
                                          id={`${section.name}-heading`}
                                          className='font-medium text-gray-900'
                                        >
                                          {section.name}
                                        </p>
                                        <ul
                                          role='list'
                                          aria-labelledby={`${section.name}-heading`}
                                          className='mt-6 space-y-6 sm:mt-4 sm:space-y-4'
                                        >
                                          {section.items.map(item => (
                                            <li
                                              key={item.name}
                                              className='flex'
                                            >
                                              <a
                                                href={item.href}
                                                className='hover:text-gray-800'
                                              >
                                                {item.name}
                                              </a>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                ))}

                {navigation.pages.map(page => (
                  <a
                    key={page.name}
                    href={page.href}
                    className='flex items-center text-sm font-medium uppercase text-secondary-600 hover:text-secondary-500'
                  >
                    {page.name}
                  </a>
                ))}
              </div>
            </Popover.Group>

            <div className='ml-auto flex items-center'>
              <div className='hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6'>
                <a
                  href='#'
                  className='text-sm font-medium text-gray-700 hover:text-gray-800'
                >
                  Sign in
                </a>
                <span className='h-6 w-px bg-gray-200' aria-hidden='true' />
                <a
                  href='#'
                  className='text-sm font-medium text-gray-700 hover:text-gray-800'
                >
                  Create account
                </a>
              </div>

              <div className='hidden lg:ml-8 lg:flex'>
                <a
                  href='#'
                  className='text-gray-700 hover:text-gray-800 flex items-center'
                >
                  <img
                    src='https://tailwindui.com/img/flags/flag-canada.svg'
                    alt=''
                    className='w-5 h-auto block flex-shrink-0'
                  />
                  <span className='ml-3 block text-sm font-medium'>CAD</span>
                  <span className='sr-only'>, change currency</span>
                </a>
              </div>

              {/* Search */}
              <div className='flex lg:ml-6'>
                <a
                  href='#'
                  className='p-2 text-primary-600 hover:text-primary-500'
                >
                  <span className='sr-only'>Search</span>
                  <SearchIcon className='w-6 h-6' aria-hidden='true' />
                </a>
              </div>

              {/* Cart */}
              <div className='ml-4 flow-root lg:ml-6'>
                <a href='#' className='group -m-2 p-2 flex items-center '>
                  <ShoppingBagIcon
                    className='flex-shrink-0 h-6 w-6 text-primary-600 group-hover:text-primary-500'
                    aria-hidden='true'
                  />
                  <span className='ml-2 text-sm font-medium text-primary-600 group-hover:text-primary-500'>
                    0
                  </span>
                  <span className='sr-only'>items in cart, view bag</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <main>
        {/* Hero section */}
        <div className='pt-16 pb-80 sm:pt-24 sm:pb-96 lg:pt-40 lg:pb-96'>
          <div className='relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static'>
            <div className='sm:max-w-lg'>
              <h1 className='text-4xl font font-anton tracking-wide uppercase text-secondary-600 sm:text-6xl'>
                {hero.title}
              </h1>
              <p className='mt-4 text-xl text-secondary-400'>{hero.subtitle}</p>
            </div>
            <div>
              <div className='mt-10'>
                {/* Decorative image grid */}
                <div
                  aria-hidden='true'
                  className='pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full'
                >
                  <div className='absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8'>
                    <div className='flex items-center space-x-6 lg:space-x-8'>
                      <div className='flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8'>
                        <div className='w-44 h-64 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100'>
                          <GatsbyImage
                            backgroundColor={true}
                            image={getProduct(i++)}
                            alt=''
                            className='w-full h-full object-center object-cover'
                          />
                        </div>
                        <div className='w-44 h-64 rounded-lg overflow-hidden'>
                          <GatsbyImage
                            backgroundColor={true}
                            image={getProduct(i++)}
                            alt=''
                            className='w-full h-full object-center object-cover'
                          />
                        </div>
                      </div>
                      <div className='flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8'>
                        <div className='w-44 h-64 rounded-lg overflow-hidden'>
                          <GatsbyImage
                            backgroundColor={true}
                            image={getProduct(i++)}
                            alt=''
                            className='w-full h-full object-center object-cover'
                          />
                        </div>
                        <div className='w-44 h-64 rounded-lg overflow-hidden'>
                          <GatsbyImage
                            backgroundColor={true}
                            image={getProduct(i++)}
                            alt=''
                            className='w-full h-full object-center object-cover'
                          />
                        </div>
                        <div className='w-44 h-64 rounded-lg overflow-hidden'>
                          <GatsbyImage
                            backgroundColor={true}
                            image={getProduct(i++)}
                            alt=''
                            className='w-full h-full object-center object-cover'
                          />
                        </div>
                      </div>
                      <div className='flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8'>
                        <div className='w-44 h-64 rounded-lg overflow-hidden'>
                          <GatsbyImage
                            backgroundColor={true}
                            image={getProduct(i++)}
                            alt=''
                            className='w-full h-full object-center object-cover'
                          />
                        </div>
                        <div className='w-44 h-64 rounded-lg overflow-hidden'>
                          <GatsbyImage
                            backgroundColor={true}
                            image={getProduct(i++)}
                            alt=''
                            className='w-full h-full object-center object-cover'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <a
                  href={hero.buttonHref}
                  className='inline-block text-center bg-primary-600 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-primary-700'
                >
                  {hero.buttonText}
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Category section */}
        <section aria-labelledby='category-heading' className='bg-gray-50'>
          <div className='max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8'>
            <div className='sm:flex sm:items-baseline sm:justify-between'>
              <h2
                id='category-heading'
                className='text-2xl font-extrabold tracking-tight text-primary-600'
              >
                Shop by Category
              </h2>
              <a
                href='#'
                className='hidden text-sm font-semibold text-primary-600 hover:text-primary-500 sm:block'
              >
                Browse all categories<span aria-hidden='true'> &rarr;</span>
              </a>
            </div>

            <div className='mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8'>
              {[
                'group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2',
                'group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:relative sm:aspect-none sm:h-full',
                'group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:relative sm:aspect-none sm:h-full'
              ].map((className, index) => (
                <div className={className}>
                  <GatsbyImage
                    image={getProduct(index)}
                    alt=''
                    className='object-center object-cover group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full'
                  />
                  <div
                    aria-hidden='true'
                    className='bg-gradient-to-b from-transparent to-black opacity-50'
                  />
                  <div className='p-6 flex items-end'>
                    <div>
                      <h3 className='font-semibold text-white'>
                        <a href='#'>
                          <span className='absolute inset-0' />
                          New Arrivals
                        </a>
                      </h3>
                      <p aria-hidden='true' className='mt-1 text-sm text-white'>
                        Shop now
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className='mt-6 sm:hidden'>
              <a
                href='#'
                className='block text-sm font-semibold text-primary-600 hover:text-primary-500'
              >
                Browse all categories<span aria-hidden='true'> &rarr;</span>
              </a>
            </div>
          </div>
        </section>

        {/* Featured section */}
        <section aria-labelledby='cause-heading'>
          <div className='relative bg-gray-800 py-32 px-6 sm:py-40 sm:px-12 lg:px-16'>
            <div className='absolute inset-0 overflow-hidden'>
              <img
                src='https://tailwindui.com/img/ecommerce-images/home-page-03-feature-section-full-width.jpg'
                alt=''
                className='w-full h-full object-center object-cover'
              />
            </div>
            <div
              aria-hidden='true'
              className='absolute inset-0 bg-gray-900 bg-opacity-50'
            />
            <div className='relative max-w-3xl mx-auto flex flex-col items-center text-center'>
              <h2
                id='cause-heading'
                className='text-3xl font-extrabold tracking-tight text-white sm:text-4xl'
              >
                Long-term thinking
              </h2>
              <p className='mt-3 text-xl text-white'>
                We're committed to responsible, sustainable, and ethical
                manufacturing. Our small-scale approach allows us to focus on
                quality and reduce our impact. We're doing our best to delay the
                inevitable heat-death of the universe.
              </p>
              <a
                href='#'
                className='mt-8 w-full block bg-white border border-transparent rounded-md py-3 px-8 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto'
              >
                Read our story
              </a>
            </div>
          </div>
        </section>

        {/* Favorites section */}
        <section aria-labelledby='favorites-heading'>
          <div className='max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8'>
            <div className='sm:flex sm:items-baseline sm:justify-between'>
              <h2
                id='favorites-heading'
                className='text-2xl font-extrabold tracking-tight text-primary-600'
              >
                Our Favorites
              </h2>
              <a
                href='#'
                className='hidden text-sm font-semibold text-primary-600 hover:text-primary-500 sm:block'
              >
                Browse all favorites<span aria-hidden='true'> &rarr;</span>
              </a>
            </div>

            <div className='mt-6 grid grid-cols-1 gap-y-10 sm:grid-cols-3 sm:gap-y-0 sm:gap-x-6 lg:gap-x-8'>
              {favorites.map(favorite => (
                <div key={favorite.id} className='group relative'>
                  <div className='w-full h-96 rounded-lg overflow-hidden group-hover:opacity-75 sm:h-auto sm:aspect-w-2 sm:aspect-h-3'>
                    <img
                      src={favorite.imageSrc}
                      alt={favorite.imageAlt}
                      className='w-full h-full object-center object-cover'
                    />
                  </div>
                  <h3 className='mt-4 text-base font-semibold text-gray-900'>
                    <a href={favorite.href}>
                      <span className='absolute inset-0' />
                      {favorite.name}
                    </a>
                  </h3>
                  <p className='mt-1 text-sm text-gray-500'>{favorite.price}</p>
                </div>
              ))}
            </div>

            <div className='mt-6 sm:hidden'>
              <a
                href='#'
                className='block text-sm font-semibold text-primary-600 hover:text-primary-500'
              >
                Browse all favorites<span aria-hidden='true'> &rarr;</span>
              </a>
            </div>
          </div>
        </section>

        {/* CTA section */}
        <section aria-labelledby='sale-heading'>
          <div className='pt-32 overflow-hidden sm:pt-14'>
            <div className='bg-primary-600'>
              <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
                <div className='relative pt-48 pb-16 sm:pb-24'>
                  <div>
                    <h2
                      id='sale-heading'
                      className='text-4xl font-extrabold tracking-tight text-white md:text-5xl'
                    >
                      Final Stock.
                      <br />
                      Up to 50% off.
                    </h2>
                    <div className='mt-6 text-base'>
                      <a href='#' className='font-semibold text-white'>
                        Shop the sale<span aria-hidden='true'> &rarr;</span>
                      </a>
                    </div>
                  </div>

                  <div className='absolute -top-32 left-1/2 transform -translate-x-1/2 sm:top-6 sm:translate-x-0'>
                    <div className='ml-24 flex space-x-6 min-w-max sm:ml-3 lg:space-x-8'>
                      <div className='flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8'>
                        <div className='flex-shrink-0'>
                          <GatsbyImage
                            image={getImage(0)}
                            alt=''
                            className='h-64 w-64 rounded-lg object-cover md:h-72 md:w-72'
                          />
                        </div>

                        <div className='mt-6 flex-shrink-0 sm:mt-0'>
                          <GatsbyImage
                            image={getImage(1)}
                            alt=''
                            className='h-64 w-64 rounded-lg object-cover md:h-72 md:w-72'
                          />
                        </div>
                      </div>
                      <div className='flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8'>
                        <div className='flex-shrink-0'>
                          <GatsbyImage
                            image={getImage(2)}
                            alt=''
                            className='h-64 w-64 rounded-lg object-cover md:h-72 md:w-72'
                          />
                        </div>

                        <div className='mt-6 flex-shrink-0 sm:mt-0'>
                          <GatsbyImage
                            image={getImage(3)}
                            alt=''
                            className='h-64 w-64 rounded-lg object-cover md:h-72 md:w-72'
                          />
                        </div>
                      </div>
                      <div className='flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8'>
                        <div className='flex-shrink-0'>
                          <GatsbyImage
                            image={getImage(4)}
                            alt=''
                            className='h-64 w-64 rounded-lg object-cover md:h-72 md:w-72'
                          />
                        </div>

                        <div className='mt-6 flex-shrink-0 sm:mt-0'>
                          <GatsbyImage
                            image={getImage(5)}
                            alt=''
                            className='h-64 w-64 rounded-lg object-cover md:h-72 md:w-72'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer aria-labelledby='footer-heading' className='bg-white'>
        <h2 id='footer-heading' className='sr-only'>
          Footer
        </h2>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='py-20 xl:grid xl:grid-cols-3 xl:gap-8'>
            <div className='grid grid-cols-2 gap-8 xl:col-span-2'>
              <div className='space-y-16 md:space-y-0 md:grid md:grid-cols-2 md:gap-8'>
                <div>
                  <h3 className='text-sm font-medium text-gray-900'>Shop</h3>
                  <ul role='list' className='mt-6 space-y-6'>
                    {footerNavigation.shop.map(item => (
                      <li key={item.name} className='text-sm'>
                        <a
                          href={item.href}
                          className='text-gray-500 hover:text-gray-600'
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className='text-sm font-medium text-gray-900'>Company</h3>
                  <ul role='list' className='mt-6 space-y-6'>
                    {footerNavigation.company.map(item => (
                      <li key={item.name} className='text-sm'>
                        <a
                          href={item.href}
                          className='text-gray-500 hover:text-gray-600'
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='space-y-16 md:space-y-0 md:grid md:grid-cols-2 md:gap-8'>
                <div>
                  <h3 className='text-sm font-medium text-gray-900'>Account</h3>
                  <ul role='list' className='mt-6 space-y-6'>
                    {footerNavigation.account.map(item => (
                      <li key={item.name} className='text-sm'>
                        <a
                          href={item.href}
                          className='text-gray-500 hover:text-gray-600'
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className='text-sm font-medium text-gray-900'>Connect</h3>
                  <ul role='list' className='mt-6 space-y-6'>
                    {footerNavigation.connect.map(item => (
                      <li key={item.name} className='text-sm'>
                        <a
                          href={item.href}
                          className='text-gray-500 hover:text-gray-600'
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className='mt-16 md:mt-16 xl:mt-0'>
              <h3 className='text-sm font-medium text-gray-900'>
                Sign up for our newsletter
              </h3>
              <p className='mt-6 text-sm text-gray-500'>
                The latest deals and savings, sent to your inbox weekly.
              </p>
              <form className='mt-2 flex sm:max-w-md'>
                <label htmlFor='email-address' className='sr-only'>
                  Email address
                </label>
                <input
                  id='email-address'
                  type='text'
                  autoComplete='email'
                  required
                  className='appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-primary-500 placeholder-gray-500 focus:outline-none focus:border-primary-500 focus:ring-1 focus:ring-primary-500'
                />
                <div className='ml-4 flex-shrink-0'>
                  <button
                    type='submit'
                    className='w-full bg-primary-600 border border-transparent rounded-md shadow-sm py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                  >
                    Sign up
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className='border-t border-gray-700 py-8 md:flex md:items-center md:justify-between'>
            <div className='flex space-x-6 md:order-2'>
              <PaymentIcons />
            </div>
            <p className='mt-8 text-base text-black md:mt-0 md:order-1'>
              {copyright}
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}
